import React, { useState, useEffect, Suspense, lazy } from "react";
import PrivateRoute from "components/Auth/PrivateRoute";
// Framework
import { useTranslation } from "react-i18next";
import {
  Switch,
  Link as RouterLink,
  Route,
  useLocation,
} from "react-router-dom";
import {
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
  AppBar,
  Alert,
  Snackbar,
  Drawer,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
  TextField,
  Box,
  Tooltip,
  Link,
} from "@mui/material";
import useAppSelector from "hooks/useAppSelector";
import useAppDispatch from "hooks/useAppDispatch";
import { hide } from "store/reducers/notifications.reducer";

// MaterialUI Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import BuilderIcon from "assets/icons/BuilderIcon";
import DashboardIcon from "@mui/icons-material/DonutLarge";
import StrategiesIcon from "assets/icons/StrategiesIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import FolderIcon from "@mui/icons-material/Folder";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import TimelineIcon from "@mui/icons-material/Timeline";
import HelpIcon from "@mui/icons-material/Help";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import AdbIcon from '@mui/icons-material/Adb';

// types
import type { TFunction } from "react-i18next";
import type { ChangeEvent } from "react";

// Assets
import Logo from "assets/img/logoIcon.png";
import LogoTitle from "assets/img/logo_title.svg";
import Spanish from "assets/icons/Spanish";
import British from "assets/icons/British";

// Components & Views
import useOrientation from "hooks/useOrientation";
import RotateView from "components/common/RotateView";
import Dashboard from "views/Dashboard";
import UserZone from "components/common/UserZone";
import Login from "components/Auth/Login";
import BinanceIcon from "assets/icons/BinanceIcon";
import customAxios from "lib/axios";
import axios from "axios";
import LoadingShow from "components/common/LoadingShow";

import OpenAccount from "components/TradingRobots/OpenAccount";
import CreateAccount from "components/TradingRobots/CreateAccount";
import LinkAccount from "components/TradingRobots/LinkAccount";
import LinkingProgress from "components/TradingRobots/LinkingProgress";
import RobotSelectModal from "components/TradingRobots/RobotSelectModal";

// Pages with lazy import
const TradingRobots = lazy(() => import("views/TradingRobots"));
const Robots = lazy(() => import("views/Robots"));
const MyValidation = lazy(() => import("views/MyValidation"));
const Binance = lazy(() => import("views/Binance"));
const Builder = lazy(() => import("views/Builder"));
const BuilderMobile = lazy(() => import("mobile/BuilderMobile"));
const ValidationMobile = lazy(() => import("mobile/ValidationMobile"));
const Profile = lazy(() => import("views/Profile"));
const Strategies = lazy(() => import("views/Strategies"));
const BalanceChart = lazy(() => import("views/BalanceChart"));
const Validation = lazy(() => import("views/Validation"));
const TradingView = lazy(() => import("views/TradingView"));
const Download = lazy(() => import("views/Download"));

const drawerWidth = 240;
const drawerSmallWidth = 70;

const links = [
  {
    id: 0,
    to: "/dashboard",
    active: true,
    name: "dashboard",
    label: "tabs.dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: 8,
    to: "/tradingrobots/main",
    active: true,
    name: "tradingrobots",
    label: "tabs.tradingrobots",
    icon: <AdbIcon />,
  },
  //{
  //  id: 7,
  //  to: "/robots",
  //  active: true,
  //  name: "robots",
  //  label: "tabs.robots",
  //  icon: <SmartToyIcon />,
  //},
    {
    id: 1,
    to: "/builder",
    active: true,
    name: "builder",
    label: "tabs.builder",
    icon: <BuilderIcon />,
  },
  {
    id: 2,
    to: "/myvalidation",
    active: true,
    name: "myvalidation",
    label: "tabs.myvalidation",
    icon: <FolderIcon />,
  },
  {
    id: 3,
    to: "/strategies",
    active: true,
    name: "strategies",
    label: "tabs.strategies",
    icon: <StrategiesIcon />,
  },
  {
    id: 4,
    to: "/tradingview",
    active: true,
    name: "tradingview",
    label: "tabs.tradingview",
    icon: <TimelineIcon />,
  },
  {
    id: 5,
    to: "/binance",
    active: false,
    name: "binance",
    label: "Binance",
    icon: <BinanceIcon />,
  },
  {
    id: 6,
    to: "/profile",
    active: true,
    name: "profile",
    label: "tabs.user-settings",
    icon: <SettingsIcon />,
  },
];

const languages = [
  { value: "es", icon: <Spanish /> },
  { value: "en", icon: <British /> },
];

type DrawerContentProps = {
  t: TFunction<"global">;
  activePage: string;
};

const DrawerContent = function ({ t, activePage }: DrawerContentProps) {
  return (
    <List sx={{ border: 0 }}>
      {links
        .filter((link) => link.active)
        .map((link) => (
          <ListItemButton
            component={RouterLink}
            key={link.id}
            to={link.to}
            selected={activePage === link.to}
            sx={{
              borderRadius: 0,
              color: "#85858C",
              "& .MuiListItemIcon-root": {
                color: "#85858C",
                fill: "#85858C",
              },
              "&.MuiListItemButton-root.Mui-selected": {
                color: "primary.main",
                fill: "primary.main",
                backgroundColor: ["rgba(39, 170, 225, 0.2)", "!important"],
                borderRight: "3px solid #27AAE1",
                "& .MuiListItemIcon-root": {
                  color: "primary.main",
                  fill: "primary.main",
                },
              },
            }}
          >
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={t(link.label)} />
          </ListItemButton>
        ))}
      <ListItemButton
        component="a"
        href="https://web.tradeasy.tech/blog/"
        target="_blank"
        sx={{ color: "#85858C" }}
      >
        <ListItemIcon sx={{ color: "#85858C" }}>
          <NewspaperIcon />
        </ListItemIcon>
        <ListItemText primary={t("tabs.news")} />
      </ListItemButton>
      <ListItemButton
        component="a"
        href="https://help.tradeasy.tech/"
        target="_blank"
        sx={{ color: "#85858C" }}
      >
        <ListItemIcon sx={{ color: "#85858C" }}>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t("tabs.help")} />
      </ListItemButton>
    </List>
  );
};
const isAuthenticated = () => {
  const userId = localStorage.getItem("user_id");
  if (!userId) {
    return false;
  }
  return true;
}
const Router = () => {
  const { isDesktop, isPortrait } = useOrientation();
  return (
    <Suspense fallback={<LoadingShow />}>
      <Switch>
        <Route exact path={["/dashboard", "/"]} component={Dashboard} />
        <PrivateRoute
              path="/tradingrobots/main"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <TradingRobots />
        </PrivateRoute>
        <PrivateRoute
              path="/tradingrobots/OpenAccount"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <OpenAccount />
        </PrivateRoute>
        <PrivateRoute
              path="/tradingrobots/CreateAccount"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <CreateAccount />
        </PrivateRoute>
        <PrivateRoute
              path="/tradingrobots/LinkAccount"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <LinkAccount />
        </PrivateRoute>
        <PrivateRoute
              path="/tradingrobots/LinkingProgress"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <LinkingProgress />
        </PrivateRoute>
        <PrivateRoute
              path="/tradingrobots/RobotSelectModal"
              // component={TradingRobots}
              isAuthenticated={isAuthenticated()}
            >
              <RobotSelectModal />
        </PrivateRoute>
        {/* <Route path="/tradingrobots/main" component={TradingRobots} />
        <Route path="/tradingrobots/OpenAccount" component={OpenAccount} />
        <Route path="/tradingrobots/CreateAccount" component={CreateAccount} />
        <Route path="/tradingrobots/LinkAccount" component={LinkAccount} />
        <Route path="/tradingrobots/LinkingProgress" component={LinkingProgress} />
        <Route path="/tradingrobots/RobotSelectModal" component={RobotSelectModal} /> 
        <Route path="/robots" component={Robots} />*/}
        <Route
          path="/builder"
          component={
            isDesktop
              ? Builder
              : isPortrait
              ? () => <RotateView title="titles.builder" />
              : BuilderMobile
          }
        />
        <Route path="/myvalidation" component={MyValidation} />
        <Route path="/strategies" component={Strategies} />
        <Route path="/tradingview" component={TradingView} />
        <Route path="/binance" component={Binance} />
        <Route path="/profile" component={Profile} />
        <Route path="/download" component={Download} />
        <Route path="/news" />
        <Route
          path="/validation"
          component={
            isDesktop
              ? Validation
              : isPortrait
              ? () => <RotateView title="titles.validation" />
              : ValidationMobile
          }
        />
        <Route path="/validation" component={Validation} />
        <Route
          exact
          path={["/generate_balance_chart", "/"]}
          component={BalanceChart}
        />
      </Switch>
    </Suspense>
  );
};

const App = () => {
  const [t, i18n] = useTranslation("global");
  const [isUserFree, setIsUserFree] = useState(false);
  const [u] = useTranslation("freemium");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [page, setPage] = useState("dashboard");
  const notifications = useAppSelector((state) => state.notifications);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const UrlPath = window.location.pathname.split("/")[1];
  const isDashboard = !!(UrlPath === "dashboard" || UrlPath === "");

  const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>) => {
    i18n.changeLanguage(event.target.value);
  };
  const handleSnackbarClose = () => {
    dispatch(hide());
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    let actualLocation = location.pathname;
    if (actualLocation === "/") {
      actualLocation = "/dashboard";
    }
    setPage(actualLocation);
    return () => {};
  }, [location, location.pathname]);

  useEffect(() => {
    const SOURCE = axios.CancelToken.source();
    async function fetchApi() {
      try {
        const { data } = await customAxios(
          `/user/${localStorage.getItem("user_id")}/validationCount`,
          { cancelToken: SOURCE.token }
        );

        if (
          Number(localStorage.getItem("user_valid_count")) !==
          data.validation_count
        ) {
          localStorage.setItem("user_valid_count", data.validation_count);
        }
        if (localStorage.getItem("type_user") !== data.type_user) {
          localStorage.setItem("type_user", data.type_user);
          setIsUserFree(data.type_user === "F");
        }
      } catch (err) {
        localStorage.setItem("user_valid_count", "0");
      }
    }

    fetchApi();

    return () => {
      SOURCE.cancel();
    };
  }, []);

  return (
    <>
      <Login />
      <Box display="flex" width="100%" height="100%" bgcolor="#f7f7fa">
        <Box
          component="nav"
          sx={{
            transition: (theme) =>
              theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            width: { md: isDashboard ? drawerWidth : drawerSmallWidth },
            flexShrink: { md: 0 },
            "&:hover": {
              width: drawerWidth,
            },
          }}
        >
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                "& .MuiDrawer-paper": {
                  border: 0,
                  width: drawerWidth,
                  backgroundColor: "#1A2B47",
                  color: "#CECED4",
                  flexShrink: 0,
                  whiteSpace: "nowrap",
                },
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <Box width="100%" p={4}>
                <img src={LogoTitle} width="100%" alt="tradEAsy Logo" />
              </Box>
              <DrawerContent t={t} activePage={page} />
            </Drawer>
          </Hidden>
          <Hidden mdDown implementation="js">
            <Drawer
              sx={{
                "& .MuiDrawer-paper": {
                  transition: (theme) =>
                    theme.transitions.create("width", {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                  overflowX: "hidden",
                  border: 0,
                  width: isDashboard ? drawerWidth : drawerSmallWidth,
                  backgroundColor: "#1A2B47",
                  color: "#CECED4",
                  flexShrink: 0,
                  whiteSpace: "nowrap",
                  "&:hover": { width: drawerWidth },
                },
              }}
              variant="permanent"
              open
            >
              <Box width={drawerWidth} py={4}>
                <ListItem
                  component="a"
                  target="_blank"
                  href={process.env.REACT_APP_TRADEASY_WEBPAGE}
                >
                  <ListItemIcon>
                    <img src={Logo} width="25px" alt="tradEAsy Logo" />
                  </ListItemIcon>
                  <ListItemText>
                    <img src={LogoTitle} width="100%" alt="tradEAsy Logo" />
                  </ListItemText>
                </ListItem>
              </Box>
              <DrawerContent t={t} activePage={page} />
            </Drawer>
          </Hidden>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          overflow="auto"
        >
          <AppBar
            sx={{ flexDirection: "row", alignItems: "center", py: 0.5, px: 2 }}
            position="sticky"
            color="transparent"
            elevation={0}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                display: {
                  md: "none",
                },
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            {UrlPath === "validation" && (
              <IconButton
                component={RouterLink}
                to="/builder"
                size="medium"
                sx={{ mr: 1 }}
                color="primary"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            {UrlPath === "download" && (
              <IconButton
                onClick={() => window.history.back()}
                size="medium"
                sx={{ mr: 1 }}
                color="primary"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Typography
              variant="h5"
              noWrap
              sx={{
                display: { xs: "none", md: "block" },
              }}
              fontFamily="Catamaran"
              fontWeight={500}
            >
              {UrlPath ? t(`titles.${UrlPath}`) : t("titles.dashboard")}
            </Typography>
            {isUserFree && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginLeft="auto"
              >
                <Typography>{u("former")}</Typography>&nbsp;
                <Link href={u("plans_link")} target="_blank" component="a">
                  {u("improve")}
                </Link>
              </Box>
            )}
            <Box marginLeft="auto" display="flex" my={1} alignItems="center">
              <Tooltip
                title={<Typography variant="body2">{t("help")}</Typography>}
                arrow
              >
                <IconButton
                  size="small"
                  href={process.env.REACT_APP_TRADEASY_HELP}
                  target="_blank"
                  component="a"
                >
                  <HelpIcon htmlColor="#CECED4" />
                </IconButton>
              </Tooltip>

              <Box
                display="flex"
                alignItems="center"
                bgcolor="white"
                borderRadius="5px"
                mx={1}
                p={0.5}
              >
                <TextField
                  id="language-selector"
                  select
                  onChange={handleLanguageChange}
                  value={i18n.language}
                  sx={{
                    p: 0,
                    "& .MuiOutlinedInput-notchedOutline": { border: 0 },
                    "& .MuiOutlinedInput-input": { p: 0, display: "flex" },
                  }}
                >
                  {languages.map((language) => (
                    <MenuItem
                      value={language.value}
                      key={language.value}
                      sx={{
                        display: "flex",
                        "& .MuiListItemIcon-root": { minWidth: 0 },
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: 0 }}>
                        {language.icon}
                      </ListItemIcon>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <UserZone />
            </Box>

            {localStorage.getItem("type_user") === "F" && (
              <Box
                width="100%"
                sx={{ display: { md: "none" } }}
                display="block"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginLeft="auto"
                  textAlign="center"
                >
                  <Typography>
                    {t("premiumWarning.former")}&nbsp;
                    <p>{t("premiumWarning.later")}</p>
                  </Typography>
                </Box>
              </Box>
            )}
          </AppBar>

          <Box
            component="main"
            height="100%"
            width="100%"
            display="flex"
            flexDirection="column"
            overflow="auto"
          >
            <Router />
          </Box>
        </Box>
      </Box>
      <Snackbar
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
        open={notifications.show}
      >
        <Alert
          severity={notifications.type}
          variant="filled"
          onClose={handleSnackbarClose}
        >
          {notifications.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default App;
